import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';
import User from '@iconify-icons/ant-design/user-outline';
import { Icon } from '@iconify/react';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import ChaletOutlinedIcon from '@material-ui/icons/ChaletOutlined';
import AgricultureOutlinedIcon from '@material-ui/icons/AgricultureOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import YardOutlinedIcon from '@material-ui/icons/YardOutlined';

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
	dashboard: <MIcon src={path('ic_dashboard')} />,
	page: <MIcon src={path('ic_page')} />,
	users: <Icon icon={User} />,
	people: <PeopleAltOutlined />,
	farms: <ChaletOutlinedIcon />,
	farmers: <AgricultureOutlinedIcon />,
	distributors: <AccountTreeOutlinedIcon />,
	product: <YardOutlinedIcon />,
	batch: <AccountTreeOutlinedIcon />,
};

const navConfig = [
	{
		items: [
			{
				title: 'Farms',
				href: PATH_APP.farm.list,
				icon: ICONS.farms,
			},
		],
	},
	{
		items: [
			{
				title: 'Products',
				href: PATH_APP.product.list,
				icon: ICONS.product,
			},
		],
	},
	{
		items: [
			{
				title: 'Activities',
				href: PATH_APP.activity.list,
				icon: ICONS.farmers,
			},
		],
	},
	{
		items: [
			{
				title: 'Batches',
				href: PATH_APP.batch.list,
				icon: ICONS.batch,
			},
		],
	},

	{
		subheader: 'admin',
		items: [
			{
				title: 'Users',
				href: PATH_APP.admin.users.list,
				icon: ICONS.people,
			},
			{
				title: 'Farmers',
				href: PATH_APP.farmer.list,
				icon: ICONS.farmers,
			},
			{
				title: 'Distributors',
				href: PATH_APP.distributor.list,
				icon: ICONS.distributors,
			},
		],
	},
];

export default navConfig;
